import React, { memo } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { iUserPatientProfilePrescription } from 'services/user-patient-profile-prescriptions';
import { Grid } from '@material-ui/core';
import { useSourceDoctors } from 'components/hooks';
import { useFieldProps, useFormDefaultValue } from 'hooks';
import { MedicalPrescriptionLastDate } from '../last-date';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { useTranslate } from 'hooks/use-translate';
import { composeFunctions } from 'utils';
import { AppInput } from 'components/app-input';
import { AppSelect } from 'components/app-select';
import { AppDatePicker } from 'components/app-date-picker';
import { AppCheckbox } from 'components/app-checkbox';
import style from './index.module.scss';

interface Props {
  formLoading: boolean;
  formUse: UseFormMethods<iUserPatientProfilePrescription>;
  lastDate?: string;
  children?: React.ReactNode;
  userPatientProfileID: string;
  onWasChanged?: () => void;
  slotBottom?: React.ReactNode;
}

export const MedicalPrescriptionForm = memo<Props>(
  ({
    userPatientProfileID,
    formLoading,
    formUse: { register, errors, control, setValue, watch },
    onWasChanged,
    children,
    slotBottom,
  }) => {
    const { t } = useTranslate();

    const getFieldProps = useFieldProps({ errors });

    const sourceDoctors = useSourceDoctors(true);
    useFormDefaultValue(sourceDoctors.data[0]?.id, {
      name: 'userEmployeeProfileID',
      setValue,
      watch,
      emptyValue: '',
    });

    const id = watch('id');

    return (
      <div className={style.root}>
        <input {...register('id')} type="hidden" />
        <input {...register('isActive')} type="hidden" />
        <input {...register('isCompleted')} type="hidden" />
        <input {...register('userPatientProfileID')} type="hidden" />

        <Grid container spacing={2}>
          <Grid item md={3}>
            <Controller
              name={'entryDate'}
              control={control}
              rules={{
                required: true,
              }}
              render={(renderProps) => {
                const props = getFieldProps(renderProps);
                return (
                  <AppDatePicker
                    {...props}
                    disabled={formLoading}
                    format={APP_FORMAT_DATE_TIME}
                    onChange={(e) => {
                      props.onChange(e);
                      onWasChanged && onWasChanged();
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={4}>
            <Controller
              name={'userEmployeeProfileID'}
              control={control}
              rules={{
                required: true,
              }}
              render={(renderProps) => {
                const props = getFieldProps(renderProps);
                return (
                  <AppSelect
                    {...props}
                    onChange={composeFunctions(props.onChange, onWasChanged)}
                    label={t('doctor')}
                    disabled={formLoading}
                    loading={sourceDoctors.loading}
                    options={sourceDoctors.data}
                    disableClearable
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={true}>
            <MedicalPrescriptionLastDate
              userPatientProfileID={userPatientProfileID}
              userPatientProfilePrescriptionID={id}
            />
          </Grid>
        </Grid>
        {children}
        <Grid container spacing={2}>
          <Grid item md={true}>
            <Controller
              name={'remarks'}
              control={control}
              render={(renderProps) => {
                const props = getFieldProps(renderProps);
                return (
                  <AppInput
                    {...props}
                    onChange={composeFunctions(props.onChange, onWasChanged)}
                    disabled={formLoading}
                    multiline
                    minRows={4}
                    disableClearable
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={'auto'}>
            <Controller
              name={'isSample'}
              control={control}
              render={(renderProps) => {
                const props = getFieldProps(renderProps);
                return (
                  <AppCheckbox
                    {...props}
                    onChange={composeFunctions(props.onChange, onWasChanged)}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        {slotBottom}
      </div>
    );
  },
);
