import React, { useCallback } from 'react';
import { GridControlsDropDownTemplateProps } from 'models/grid';
import { useTranslate } from 'hooks/use-translate';
import { composeFunctions } from 'utils';
import { CustomHandler, GridModel } from '../models';
import { useGridMedicalPrescriptions } from '../hooks';
import { BaseMenuItem } from '../base-menu-item';

interface Props extends GridControlsDropDownTemplateProps<GridModel> {}
const Control: React.FC<Props> = ({ data, handleClose }) => {
  const { tp } = useTranslate();
  const disabled = !data.prescriptionFilePath;
  const { onTasks } = useGridMedicalPrescriptions();
  const onClick = useCallback(() => {
    onTasks([['onHandle', { type: CustomHandler.SEND_TO_PHARMACY, data }]]);
  }, [onTasks, data]);

  if (data.isSample) return null;

  return (
    <BaseMenuItem
      prescriptionID={String(data.id)}
      onClick={composeFunctions(onClick, handleClose)}
      disabled={disabled}
    >
      {tp('send-prescription-to-pharmacy')}
    </BaseMenuItem>
  );
};

export const ControlSendToPharmacy: React.FC<Props> = (props) => {
  if (props.data.isSample) return null;

  return <Control {...props} />;
};
