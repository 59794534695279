import { API_USER_PATIENT_PROFILE_PRESCRIPTIONS } from 'services/user-patient-profile-prescriptions';
import React from 'react';
import { format } from 'date-fns';
import { permissionKey } from 'services/user-employee-profile-permissions';
import { APP_FORMAT_DATE_TIME } from 'configs/const';
import { convertToDate } from 'utils/dates';
import { GridModel } from './models';
import { ControlSendToPatient } from './control-send-to-patient';
import { ControlSendCopyToPatient } from './control-send-copy-to-patient';
import { ControlSendToPharmacy } from './control-send-to-pharmacy';
import { ControlEdit } from './control-edit';
import { ControlDownload } from './control-download';
import { ColumnActivities } from 'components/user-patient-prescription-activities';
import { TemplateDownloadForClinic } from './template-download-for-clinic';
import { TemplateDownloadForPatient } from './template-download-for-patient';
import { ControlSendToNeopharm } from './control-send-to-neopharm';
import { TemplateDate } from 'components/templates/template-date';
import { IDataGridCellProps } from 'components/data-grid/model';
import {
  TemplatePrescriptionDrugs,
  ControlPrescriptionOnHold,
  ControlDelete,
} from 'components/medical-prescription';
import { TemplateDownloadForPrint } from './template-download-for-print';

const calcCellProps: IDataGridCellProps<GridModel> = ({ row }) => {
  return { style: { backgroundColor: 'transparent' } };
};

interface Options {
  userPatientProfileID: string;
  isAdmin: boolean;
}
export default ({ userPatientProfileID, isAdmin }: Options): any => ({
  id: 'grid_user-patient-profile-prescriptions',
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_PRESCRIPTIONS.GET_ALL_DYNAMIC,
      selected: ['isCompleted', 'id', 'pharmacyFollowupID', 'userPatientProfileID'].join(','),
      filters: `userPatientProfileID=="${userPatientProfileID}"`,
      parser: (value: any[]) =>
        value.map((item) => {
          let html = item.remarks || '';
          if (html.indexOf('\n') === 0) html = html.slice(1);
          return { ...item, remarks: html.replace('\n', '<br/>') };
        }),
    },
    create: {
      url: API_USER_PATIENT_PROFILE_PRESCRIPTIONS.POST,
    },
    patch: {
      url: API_USER_PATIENT_PROFILE_PRESCRIPTIONS.PATCH,
    },
    delete: {
      url: API_USER_PATIENT_PROFILE_PRESCRIPTIONS.DELETE,
      title: (data: GridModel) => `${format(convertToDate(data.entryDate), APP_FORMAT_DATE_TIME)}`,
    },
    sortable: {
      field: 'entryDate',
      order: 'desc',
    },
  },
  controlsDropDown: [
    {
      type: 'custom',
      template: ControlSendToPatient,
      permission: permissionKey('isAllowToSendPrescriptionToPatient'),
    },
    {
      type: 'custom',
      template: ControlSendCopyToPatient,
      permission: permissionKey('isAllowToSendPrescriptionCopyToPatient'),
    },
    {
      type: 'custom',
      template: ControlSendToPharmacy,
      permission: permissionKey('isAllowToSendPrescriptionToPharmacy'),
    },
    {
      type: 'custom',
      template: ControlEdit,
      permission: permissionKey('isAllowToCreateAndRenewPrescriptions'),
    },
    {
      type: 'custom',
      template: ControlSendToNeopharm,
      permission: permissionKey('isAllowToSendPrescriptionToPharmacy'),
    },
    {
      type: 'custom',
      template: ControlDownload,
    },
    {
      type: 'custom',
      template: ControlPrescriptionOnHold,
    },
    {
      type: 'custom',
      template: ControlDelete,
      permission: permissionKey('isAllowToDeleteAnything'),
    },
  ],
  toolbar: {
    title: '',
    controls: null,
    controlsDefault: {
      new: {
        permission: permissionKey('roleDoctor'),
      },
    },
  },
  columns: [
    ...(isAdmin
      ? [
          {
            title: 'created-date',
            field: 'createdDate',
            template: {
              type: 'date',
              format: APP_FORMAT_DATE_TIME,
            },
            tdClasses: 'mobile-no-wrap',
            cellProps: calcCellProps,
          },
        ]
      : []),
    {
      title: 'date',
      field: 'entryDate',
      template: {
        type: 'date',
        format: APP_FORMAT_DATE_TIME,
      },
      tdClasses: 'mobile-no-wrap',
      cellProps: calcCellProps,
    },
    {
      title: 'doctor',
      field: 'userEmployeeProfile.fullName as employeeFullName',
      sortable: 'userEmployeeProfile.firstName',
      tdClasses: 'mobile-no-wrap',
      cellProps: calcCellProps,
    },
    {
      title: 'drugs',
      field: 'userPatientProfilePrescriptionDetails.Select(s=>s.drug.catalogName) as drugs',
      template: (data: GridModel) => <TemplatePrescriptionDrugs id={data.id} drugs={data.drugs} />,
      sortable: false,
      cellProps: calcCellProps,
    },
    {
      title: 'remarks',
      field: 'remarks',
      template: 'html',
      tdClasses: 'mobile-20',
      cellProps: calcCellProps,
    },
    {
      title: 'prescription-for-clinic',
      field: 'prescriptionFilePath',
      template: (data: GridModel) => (
        <TemplateDownloadForClinic
          userPatientProfilePrescriptionID={data.id}
          documentURL={data.prescriptionFilePath}
        />
      ),
      cellProps: calcCellProps,
    },
    {
      title: 'prescription-for-print',
      field: 'prescriptionPrintFilePath',
      template: (data: GridModel) => (
        <TemplateDownloadForPrint
          userPatientProfilePrescriptionID={data.id}
          documentURL={data.prescriptionPrintFilePath}
        />
      ),
      cellProps: calcCellProps,
    },
    {
      title: 'prescription-for-patient',
      field: 'prescriptionWatermarkFilePath',
      template: (data: GridModel) => (
        <TemplateDownloadForPatient
          userPatientProfilePrescriptionID={data.id}
          documentURL={data.prescriptionWatermarkFilePath}
        />
      ),
      cellProps: calcCellProps,
    },
    {
      title: 'activities',
      field: 'userPatientProfilePrescriptionActivities.Count() as totalActivities',
      sortable: 'userPatientProfilePrescriptionActivities.Count()',
      template: (data: GridModel) => (
        <ColumnActivities
          userPatientProfilePrescriptionID={data.id}
          activities={data.totalActivities}
        />
      ),
      cellProps: calcCellProps,
    },
    {
      title: 'is-sample',
      field: 'isSample',
      template: 'boolean',
      cellProps: calcCellProps,
    },
    {
      title: 'is-on-hold',
      field: 'isOnHold',
      template: 'boolean',
      cellProps: calcCellProps,
    },
    {
      title: 'on-hold-date',
      field: 'onHoldDate',
      template: (row: GridModel) => <TemplateDate date={row.onHoldDate} />,
      tdClasses: 'mobile-no-wrap',
      cellProps: calcCellProps,
    },
    {
      title: 'is-completed',
      field: 'isCompleted',
      template: 'boolean',
      cellProps: calcCellProps,
    },
    {
      title: 'is-sent',
      field: 'isSent',
      template: 'boolean',
      cellProps: calcCellProps,
    },
    {
      title: 'is-print',
      field: 'isPrint',
      template: 'boolean',
      cellProps: calcCellProps,
    },
  ],
});
