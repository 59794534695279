import React, { useMemo } from 'react';
import { Button, Grid, Slider } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { FilterModel } from '../../models';
import { useModelTypeOptions } from '../../hooks';
import RefreshIcon from '@material-ui/icons/Refresh';
import style from './index.module.scss';
import renderColoredIconOption from 'components/select-render-components/render-colored-icon-option';
import renderColoredIconTags from 'components/select-render-components/render-colored-icon-tags';
import { useTranslate } from 'hooks/use-translate';
import { AppSelect } from 'components/app-select';
import { addYears } from 'date-fns';
import { useFilterForm } from 'hooks/use-filter-form';
import { composeFunctions } from 'utils';
import { calcRangeSlider } from 'utils/app-helpers';

const makeYearsSource = (startYear: number) => {
  const START = startYear;
  const endDate = new Date();

  const currentYear = addYears(endDate, 1).getFullYear();
  const length = currentYear - (START - 1);
  return new Array(length).fill(null).map((_, i) => {
    const id = currentYear - i;
    return { id, title: String(id) };
  });
};

interface ToolsProps {
  startYear: number | null;
  filters: FilterModel;
  setFilters: (value: FilterModel) => void;
  isLoading: boolean;
  onRefresh: () => void;
}
export const Tools: React.FC<ToolsProps> = ({
  isLoading,
  filters,
  setFilters,
  onRefresh,
  startYear,
}) => {
  const { t } = useTranslate();

  const { control, register, setValue, watch, onCheckAndUpdate, getValues } = useFilterForm({
    defaultValues: filters,
    onUpdate: setFilters,
  });

  const from = watch('fromYear');
  const to = watch('toYear');
  const sliderValue = [from, to];

  const marks = useMemo(() => {
    if (!startYear) return [];

    return makeYearsSource(startYear)
      .map(({ id, title }) => ({ value: id, label: title }))
      .sort((a, b) => a.value - b.value);
  }, [startYear]);

  const sliderProps = useMemo(() => {
    const values = marks.map(({ value }) => value);
    const min = Math.min(...values);
    const max = Math.max(...values);

    return {
      marks,
      min,
      max,
      onChange: (e: React.ChangeEvent<{}>, value: number | number[]) => {
        if (!Array.isArray(value)) {
          return;
        }

        const oldValue = getValues('fromYear');
        const activeThumb = oldValue === value[0] ? 1 : 0;

        const res = calcRangeSlider({ value, marks, activeThumb, minStep: 0, maxStep: 2 });

        setValue('fromYear', res[0]);
        setValue('toYear', res[1]);
      },
    };
  }, [setValue, getValues, marks]);

  const options = useModelTypeOptions();

  return (
    <>
      <input {...register('fromYear')} hidden />
      <input {...register('toYear')} hidden />
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item xs={12}>
          <Slider
            value={sliderValue}
            step={null}
            {...sliderProps}
            onChangeCommitted={onCheckAndUpdate}
          />
        </Grid>
        <Grid item lg={true}>
          <Controller
            name={'types'}
            control={control}
            render={({ value, onChange }) => {
              return (
                <AppSelect
                  label={t('types')}
                  RenderInputProps={{
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                  multiple
                  placeholder={t('all-types')}
                  value={value}
                  options={options}
                  onChange={composeFunctions(onChange, onCheckAndUpdate)}
                  renderOption={renderColoredIconOption}
                  renderTags={renderColoredIconTags}
                  className={style.select}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={'auto'} style={{ width: 'unset' }}>
          <Button
            color={'primary'}
            variant={'contained'}
            onClick={onRefresh}
            disabled={isLoading}
            startIcon={<RefreshIcon />}
          >
            {t('refresh')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
