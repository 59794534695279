import React, { useRef } from 'react';
import { ShortRemark } from 'components/short-remark';
import { ChipProps, ClickAwayListener, Paper, Popper } from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { PrescriptionSamples } from 'components/medical-prescription';
import { useOpen } from 'AurionCR/components/hooks';
import {
  apiUserPatientPrescriptions,
  MedicalPrescriptionSample,
} from 'services/user-patient-profile-prescriptions';

const useFetchQuery = apiUserPatientPrescriptions.useGetMedicalPrescriptionsSamplesQuery;

interface BadgeSamplesComponentProps extends Omit<ChipProps, 'children' | 'label'> {
  rows: MedicalPrescriptionSample[];
}
const BadgeSamplesComponent: React.FC<BadgeSamplesComponentProps> = ({ rows, ...rest }) => {
  const { tp } = useTranslate();

  const ref = useRef<HTMLDivElement>(null);

  const { isOpen, handleOpen, handleClose } = useOpen();

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div onMouseEnter={handleOpen} onMouseLeave={handleClose}>
        <ShortRemark ref={ref} {...rest} priority={'low'}>
          {tp('got-samples', { count: rows.length })}
          <Popper open={isOpen} anchorEl={ref.current} style={{ zIndex: 1301 }}>
            <Paper>
              <PrescriptionSamples isLoading={false} rows={rows} />
            </Paper>
          </Popper>
        </ShortRemark>
      </div>
    </ClickAwayListener>
  );
};

interface Props extends Omit<ChipProps, 'children' | 'label'> {
  userPatientProfileID: string;
}
export const BadgeSamples: React.FC<Props> = ({ userPatientProfileID, ...rest }) => {
  const { data } = useFetchQuery(userPatientProfileID);

  if (!data || data.length === 0) return null;

  return <BadgeSamplesComponent rows={data} {...rest} />;
};
