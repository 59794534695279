import { DynamicParams, DynamicService } from 'utils/service';
import * as dynamic from 'utils/dynamic-helpers';

import {
  API_USER_PATIENT_PROFILE_SUBSCRIPTIONS,
  PatientSubscription,
  RefundPatientSubscriptionInput,
  UpdatePatientSubscriptionEndDateInput,
  UserPatientProfileSubscription,
  UserPatientSubscriptionForRefund,
  UserPatientSubscriptionsForEndOfTreatment,
  UserPatientSubscriptionsForLetterForMedInsurance,
  UserSubscriptionGetActivitiesInput,
  UserSubscriptionGetActivitiesItem,
} from './models';
import { convertToDate } from 'utils/dates';
import { makeFilterDateRange } from 'utils/app-helpers';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { format } from 'date-fns';
import { apiRtk } from 'utils/rtk-query';
import { ServicePayment } from '../payment';
import { apiClinicalMeetings } from '../clinical-meetings';
import { apiSupportMeeting } from '../support-meetings';
import { PatchPartial } from 'utils/types';
import { SUBSCRIPTION_INDEXES } from '../subscription';
import { apiUserPatientProfileSubscriptionActivities } from '../user-patient-profile-subscription-activities';
import { apiPatientFormDocuments } from '../user-patient-profile-form-documents';

export * from './helpers';
export * from './models';

class Service extends DynamicService<UserPatientProfileSubscription> {
  getDynamicUserLatest = async <
    Model = UserPatientProfileSubscription,
    Params extends DynamicParams = DynamicParams,
  >(
    userPatientProfileID: string,
    params?: Params,
  ) => {
    const result = await this.getAllDynamic<Model>({
      ...params,
      filter: [`userPatientProfileID=="${userPatientProfileID}"`, 'isActive==true', params?.filter]
        .filter(Boolean)
        .join('&&'),
      orderBy: 'endDate desc',
      take: 1,
    });

    const data = result.data.value[0];

    if (!data) {
      throw new Error('current-subscription-not-found');
    }

    return { ...result, data };
  };
  getActivities = async (input: UserSubscriptionGetActivitiesInput) => {
    const { userPatientProfileID, dateRange } = input;

    const {
      data: { value },
    } = await this.getAllDynamic<UserSubscriptionGetActivitiesItem>({
      filter: [
        `userPatientProfileID=="${userPatientProfileID}"`,
        makeFilterDateRange('startDate', dateRange),
      ]
        .filter(Boolean)
        .join('&&'),
      select: [
        'id',
        'startDate',
        'endOfTreatmentDocUrl',
        'invoiceDocUrl',
        'subscription.labelKey as title',
        'userPatientProfileSubscriptionActivities.Count() as activities',
      ].join(','),
      orderBy: 'updatedDate desc',
    });

    return value.map((item) => ({
      id: String(item.id),
      title: item.title,
      date: item.startDate,
      employee: null,
      download: item.endOfTreatmentDocUrl || item.invoiceDocUrl,

      inactive: Boolean(item.endOfTreatmentDocUrl),
      activities: item.activities,
    }));
  };

  getDynamicByDate = async <T = UserPatientProfileSubscription>(
    date = new Date().toISOString(),
    params?: Pick<DynamicParams, 'select' | 'filter'>,
  ) => {
    const dateTime = format(convertToDate(date), 'yyyy-MM-dd HH:mm');
    const res = await this.getAllDynamic<T>({
      ...params,
      filter: [
        `(DateTime("${dateTime}") >= DateTime(startDate) && DateTime("${dateTime}") <= DateTime(endDate))`,
        params?.filter,
      ]
        .filter(Boolean)
        .join('&&'),
      take: 1,
    });

    const item = res.data.value[0];

    if (!item) {
      throw new Error('record-not-found');
    }

    return { ...res, data: item };
  };
}

export const ServiceUserPatientProfileSubscriptions = new Service({
  mainField: 'id',
  getAll: API_USER_PATIENT_PROFILE_SUBSCRIPTIONS.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_SUBSCRIPTIONS.POST,
  delete: API_USER_PATIENT_PROFILE_SUBSCRIPTIONS.DELETE,
  patch: API_USER_PATIENT_PROFILE_SUBSCRIPTIONS.PATCH,
});

export const apiUserPatientProfileSubscriptions = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getLatestPatientSubscription: builder.query<PatientSubscription, string>({
      queryFn: async (userPatientProfileID) => {
        try {
          const result =
            await ServiceUserPatientProfileSubscriptions.getDynamicUserLatest<PatientSubscription>(
              userPatientProfileID,
              {
                select: [
                  'id',
                  'startDate',
                  'endDate',
                  'subscription.labelKey as title',
                  'subscription.visitDietitian',
                ].join(','),
                filter: 'subscription.durationMonths>0',
              },
            );
          return result;
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getCurrentPatientSubscription: builder.query<PatientSubscription, string>({
      queryFn: async (userPatientProfileID: string) => {
        try {
          const result =
            await ServiceUserPatientProfileSubscriptions.getAllDynamic<PatientSubscription>({
              select: dynamic.select(
                'id',
                'startDate',
                'endDate',
                'subscription.labelKey as title',
                'subscription.visitDietitian',
              ),
              filter: dynamic
                .mergeFilters(
                  dynamic.createFilterEquals('userPatientProfileID', userPatientProfileID),
                  'subscription.durationMonths>0',
                  'DateTime.Now>=startDate && DateTime.Now<=endDate',
                  'isActive==true',
                )
                .join('&&'),
              take: 1,
            });
          return DynamicService.transformResponseToItem(result);
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getPatientSubscriptionForRefund: builder.query<UserPatientSubscriptionForRefund, string>({
      queryFn: async (userPatientProfileSubscriptionID) => {
        try {
          const moment = new Date().toISOString();
          const { data } =
            await ServiceUserPatientProfileSubscriptions.getDynamic<UserPatientSubscriptionForRefund>(
              userPatientProfileSubscriptionID,
              {
                select: [
                  'id',
                  'userPatientProfileID',
                  'subscriptionID',
                  'new { subscription.durationMonths, subscription.labelKey } as subscription',
                  'new { userPatientProfile.isActive, userPatientProfile.fullName } as userPatientProfile',
                  'startDate',
                  'endDate',
                  `userPatientProfile.clinicalMeetings.Where(m => m.clinicalMeetingSubject.isCanceledMeeting==false && m.meetingFromDateTime > DateTime("${moment}")).Select(m => m.id) as futureClinicalIDs`,
                  `userPatientProfile.supportMeetings.Where(m => m.supportMeetingType.isCanceledMeeting==false && m.meetingFromDateTime > DateTime("${moment}")).Select(m => m.id) as futureSupportIDs`,
                ].join(','),
              },
            );

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getPatientSubscriptionsForLetterForMedInsurance: builder.query<
      UserPatientSubscriptionsForLetterForMedInsurance[],
      string
    >({
      queryFn: async (userPatientProfileID) => {
        try {
          const { data } =
            await ServiceUserPatientProfileSubscriptions.getAllDynamic<UserPatientSubscriptionsForLetterForMedInsurance>(
              {
                filter: dynamic
                  .mergeFilters(
                    dynamic.createFilterEquals('userPatientProfileID', userPatientProfileID),
                    dynamic.createFilterEquals('isActive', true),
                  )
                  .join('&&'),
                select: dynamic.select(
                  'id',
                  'startDate',
                  'endDate',
                  'new { subscription.labelKey } as subscription',
                ),
                orderBy: dynamic.orderBy('paymentDate', 'desc'),
              },
            );

          return { data: data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getPatientSubscriptionsForEndOfTreatment: builder.query<
      UserPatientSubscriptionsForEndOfTreatment[],
      string
    >({
      queryFn: async (userPatientProfileID) => {
        try {
          const { data } =
            await ServiceUserPatientProfileSubscriptions.getAllDynamic<UserPatientSubscriptionsForEndOfTreatment>(
              {
                filter: dynamic
                  .mergeFilters(
                    dynamic.createFilterEquals('userPatientProfileID', userPatientProfileID),
                    dynamic.createFilterEquals('isActive', true),
                    'endOfTreatmentDocUrl==null',
                    dynamic.createFilterNotEquals(
                      'subscription.rowIndex',
                      SUBSCRIPTION_INDEXES.CONSULTATION,
                    ),
                  )
                  .join('&&'),
                select: dynamic.select(
                  'id',
                  'startDate',
                  'endDate',
                  'endOfTreatmentDocUrl',
                  'new { subscription.labelKey, subscription.rowIndex } as subscription',
                ),
                orderBy: dynamic.orderBy('paymentDate', 'desc'),
              },
            );

          return { data: data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getPatientSubscriptionsForHmoLetter: builder.query<
      UserPatientSubscriptionsForEndOfTreatment[],
      string
    >({
      queryFn: async (userPatientProfileID) => {
        try {
          const { data } =
            await ServiceUserPatientProfileSubscriptions.getAllDynamic<UserPatientSubscriptionsForEndOfTreatment>(
              {
                filter: dynamic
                  .mergeFilters(
                    dynamic.createFilterEquals('userPatientProfileID', userPatientProfileID),
                    dynamic.createFilterNotEquals(
                      'subscription.rowIndex',
                      SUBSCRIPTION_INDEXES.CONSULTATION,
                    ),
                  )
                  .join('&&'),
                select: dynamic.select(
                  'id',
                  'startDate',
                  'endDate',
                  'endOfTreatmentDocUrl',
                  'new { subscription.labelKey, subscription.rowIndex } as subscription',
                ),
                orderBy: dynamic.orderBy('paymentDate', 'desc'),
              },
            );

          return { data: data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    refundPatientSubscription: builder.mutation<void, RefundPatientSubscriptionInput>({
      queryFn: async (input, { dispatch }) => {
        try {
          await ServicePayment.refund({
            userPatientProfileID: input.userPatientProfileID,
            userPatientProfileSubscriptionID: input.userPatientProfileSubscriptionID,
            refundAmount: input.refundAmount,
            refundRemarks: input.refundRemarks,
            subscriptionID: input.subscriptionID,
            startDate: input.startDate,
            endDate: input.endDate,
          });

          if (input.isDeactivatePatient) {
            await dispatch(
              apiUserPatientProfile.endpoints.deactivatePatientByRefund.initiate(
                input.userPatientProfileID,
              ),
            );
          }

          // cancel meetings
          if (input.isCancelFutureMeetings) {
            input.futureClinicalIDs.map((clinicalMeetingID) => {
              return dispatch(
                apiClinicalMeetings.endpoints.cancelClinicalMeetingAuto.initiate(clinicalMeetingID),
              ).unwrap();
            });
            input.futureSupportIDs.map((supportMeetingID) => {
              return dispatch(
                apiSupportMeeting.endpoints.cancelSupportMeetingAuto.initiate(supportMeetingID),
              ).unwrap();
            });
          }

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    patchPatientSubscription: builder.mutation<
      void,
      PatchPartial<UserPatientProfileSubscription, 'id'>
    >({
      queryFn: async (input) => {
        try {
          await ServiceUserPatientProfileSubscriptions.patch(input);

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    updatePatientSubscriptionEndDate: builder.mutation<void, UpdatePatientSubscriptionEndDateInput>(
      {
        queryFn: async (input, { dispatch }) => {
          try {
            const { id, remarks, endDate, makeActive } = input;

            const { data: subscription } = await ServiceUserPatientProfileSubscriptions.getDynamic(
              id,
              {
                select: dynamic.select(
                  'id',
                  'userPatientProfileID',
                  `userPatientProfileFormDocuments
                    .Where(d => d.formDocument.formDocumentCategory.useForContract==true)
                    .Select(d => new { d.id })
                  as userPatientProfileFormDocuments`,
                ),
              },
            );

            const userPatientProfileID = String(subscription.userPatientProfileID);

            await dispatch(
              apiUserPatientProfileSubscriptions.endpoints.patchPatientSubscription.initiate({
                id,
                endDate,
              }),
            ).unwrap();

            // create log
            dispatch(
              apiUserPatientProfileSubscriptionActivities.endpoints.createUserPatientProfileSubscriptionActivityLogChangeEndDate.initiate(
                {
                  userPatientProfileSubscriptionID: id,
                  endDate,
                  remarks,
                },
              ),
            );
            // end create log

            // update the patient
            dispatch(
              apiUserPatientProfile.endpoints.patchPatient.initiate({
                appIdentityUserID: userPatientProfileID,
                surveyExpiredDate: endDate,
              }),
            );

            // end update the patient

            // activate patient if needed
            if (makeActive) {
              dispatch(
                apiUserPatientProfile.endpoints.activatePatient.initiate(userPatientProfileID),
              );
            }
            // end activate patient if needed

            // update the documents
            const documents = subscription.userPatientProfileFormDocuments || [];

            documents.forEach((doc) => {
              dispatch(
                apiPatientFormDocuments.endpoints.patchUserPatientFormDocument.initiate({
                  id: String(doc.id),
                  documentExpiredDate: endDate,
                }),
              );
            });
            // end update documents

            return { data: undefined };
          } catch (e: any) {
            return { error: e };
          }
        },
      },
    ),
  }),
});
