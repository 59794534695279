import React, { useMemo } from 'react';

import { useTranslate } from 'hooks/use-translate';
import { createColumn, DataGridLight } from 'components/data-grid-light';
import { Skeleton } from '@material-ui/lab';
import { TemplateDate } from 'components/templates/template-date';
import { TemplatePrescriptionDrugs } from '../template-prescription-drugs';

const renderSkeleton = () => {
  return <Skeleton />;
};

interface RowModel {
  id: string;
  createdDate: string;
  entryDate: string;
  drugs: string[];
  remarks: string;
}
interface Props {
  rows: RowModel[];
  isLoading: boolean;
  count?: number;
}

export const PrescriptionSamples: React.FC<Props> = ({ rows, isLoading, count = 2 }) => {
  const { t } = useTranslate();
  const columns = useMemo(
    () =>
      [
        createColumn<RowModel, 'createdDate'>({
          id: 'date',
          field: 'createdDate',
          renderColumn: ({ row, field }) => <TemplateDate date={row[field]} />,
        }),
        createColumn<RowModel, 'entryDate'>({
          id: 'date',
          field: 'entryDate',
          renderHeader: () => t('date'),
          renderColumn: ({ row, field }) => <TemplateDate date={row[field]} />,
        }),
        createColumn<RowModel, 'drugs'>({
          id: 'date',
          field: 'drugs',
          renderColumn: ({ row }) => <TemplatePrescriptionDrugs id={row.id} drugs={row.drugs} />,
        }),
        createColumn<RowModel, 'remarks'>({
          id: 'date',
          field: 'remarks',
          renderColumn: ({ row, field }) => row[field],
        }),
      ].map((column) => {
        if (!isLoading) {
          return column;
        }
        return { ...column, renderColumn: renderSkeleton };
      }),
    [t, isLoading],
  );

  const innerRows = useMemo(() => {
    if (!isLoading) return rows;

    return new Array(count).fill(null).map((_, i) => {
      return { id: String(i) } as unknown as RowModel;
    });
  }, [isLoading, rows, count]);

  return <DataGridLight columns={columns} rows={innerRows} />;
};
