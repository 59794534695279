import React, { useEffect, useMemo, useState } from 'react';

import style from './index.module.scss';

import { Box, BoxProps, Typography } from '@material-ui/core';
import { Loading } from 'AurionCR/components';
import clsx from 'clsx';
import { DataGridEmpty, DataGridLight, createColumn } from 'components/data-grid-light';
import { NativeScroll } from 'components/native-scroll';
import { Stack } from 'components/stack';
import { useEffectError } from 'hooks';
import { useTranslate } from 'hooks/use-translate';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { convertToDate } from 'utils/dates';
import { ColumnDate, ColumnDownload, ColumnTitle, ColumnType, Tools } from './component';
import { calcBgColor, calcRowProps } from './helpers';
import { FilterModel, MODEL_TYPE, Row } from './models';
import { apiUserPatientProfileSessions } from 'services/user-patient-profile-session';

const useQuery = apiUserPatientProfile.useGetPatientActivitiesLogYearlyQuery;
const useFetchFirstSession = apiUserPatientProfileSessions.useGetSessionFirstQuery;

const fromDate = new Date();

const defaultValues: FilterModel = {
  types: [],
  fromYear: fromDate.getFullYear() - 1,
  toYear: fromDate.getFullYear() + 1,
};

interface Props extends BoxProps {
  userPatientProfileID: string;
}

export const AllActivitiesGrid: React.FC<Props> = ({ userPatientProfileID, ...rest }) => {
  const { t, tp } = useTranslate();

  const [startYear, setStartYear] = useState<null | number>(null);
  const resultFirstSession = useFetchFirstSession({ userPatientProfileID });
  useEffectError(resultFirstSession.error);

  const [filters, setFilters] = useState(defaultValues);

  useEffect(() => {
    const session = resultFirstSession.data;

    if (session === undefined) return;

    const defaultYear = new Date().getFullYear() - 2;
    const sessionYear = convertToDate(session?.entryDate).getFullYear();

    const year = Math.min(defaultYear, sessionYear);

    setStartYear(year);
  }, [resultFirstSession.data]);

  const { types, fromYear, toYear } = filters;

  const { data, isFetching, error, refetch } = useQuery({ userPatientProfileID, fromYear, toYear });
  useEffectError(error);

  const onRefresh = refetch;

  const rows = useMemo(() => {
    const messages = data?.messages || [];
    const tasks = data?.tasks || [];
    const notes = data?.notes || [];
    const calls = data?.calls || [];
    const prescriptions = data?.prescriptions || [];
    const documents = data?.documents || [];
    const signedDocuments = data?.signedDocuments || [];
    const uploadedFiles = data?.uploadedFiles || [];
    const subscriptions = data?.subscriptions || [];
    const sessions = data?.sessions || [];
    const medicalInformation = data?.medicalInformation || [];
    const clinicalMeetings = data?.clinicalMeetings || [];
    const supportMeetings = data?.supportMeetings || [];
    const activities = data?.activities || [];

    const items: Row[] = [
      ...messages.map((item) => ({ ...item, type: MODEL_TYPE.SMS } as const)),
      ...tasks.map((item) => ({ ...item, type: MODEL_TYPE.TASK } as const)),
      ...notes.map((item) => ({ ...item, type: MODEL_TYPE.NOTE } as const)),
      ...calls.map((item) => ({ ...item, type: MODEL_TYPE.CALL } as const)),
      ...prescriptions.map((item) => ({ ...item, type: MODEL_TYPE.PRESCRIPTION } as const)),
      ...documents.map((item) => ({ ...item, type: MODEL_TYPE.DOCUMENT } as const)),
      ...signedDocuments.map((item) => ({ ...item, type: MODEL_TYPE.SIGNED_DOCUMENT } as const)),
      ...uploadedFiles.map((item) => ({ ...item, type: MODEL_TYPE.USER_DOCUMENT } as const)),
      ...subscriptions.map(
        (item) =>
          ({
            ...item,
            type: MODEL_TYPE.SUBSCRIPTION,
            title: [item.title, `${t(item.inactive ? 'inactive' : 'active')}`]
              .filter(Boolean)
              .join(' '),
            activities: item.activities,
          } as const),
      ),
      ...sessions.map((item) => ({ ...item, type: MODEL_TYPE.SESSION } as const)),
      ...medicalInformation.map(
        (item) => ({ ...item, type: MODEL_TYPE.MEDICAL_INFORMATION } as const),
      ),
      ...clinicalMeetings.map((item) => ({ ...item, type: MODEL_TYPE.CLINICAL_MEETING } as const)),
      ...supportMeetings.map((item) => ({ ...item, type: MODEL_TYPE.SUPPORT_MEETING } as const)),
      ...activities.map((item) => ({ ...item, type: MODEL_TYPE.ACTIVITIES } as const)),
    ];
    return items
      .filter((row) => {
        const listOfTypes = types || [];
        if (listOfTypes.length === 0) return true;

        return listOfTypes.some((type) => type === row.type);
      })
      .sort((a, b) => {
        const valueA = a.date ? convertToDate(a.date).getTime() : 0;
        const valueB = b.date ? convertToDate(b.date).getTime() : 0;
        return valueB - valueA;
      });
  }, [data, types, t]);

  const columns = useMemo(
    () => [
      createColumn<Row, 'type'>({
        field: 'type',
        renderColumn: ({ row }) => <ColumnType row={row} type={row.type} onRefresh={onRefresh} />,
      }),
      createColumn<Row, 'date'>({
        field: 'date',
        renderColumn: ({ row }) => <ColumnDate date={row.date} />,
      }),
      createColumn<Row, 'title'>({
        field: 'title',
        renderColumn: ({ row }) => <ColumnTitle row={row} />,
      }),
      createColumn<Row, 'employee'>({
        field: 'employee',
      }),
      createColumn<Row, 'download'>({
        field: 'download',
        renderHeader: () => null,
        stickyRight: true,
        tdProps: {
          style: { backgroundColor: 'white', padding: 0 },
        },
        renderColumn: ({ row }) => (
          <Box
            style={{ backgroundColor: calcBgColor(row.type) }}
            width={'100%'}
            height={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            p={0.6}
          >
            <ColumnDownload row={row} userPatientProfileID={userPatientProfileID} />
          </Box>
        ),
      }),
    ],
    [userPatientProfileID, onRefresh],
  );

  const isLoading = isFetching || resultFirstSession.isFetching;

  return (
    <Box className={clsx(style.root)} {...rest}>
      <Tools
        startYear={startYear}
        filters={filters}
        setFilters={setFilters}
        isLoading={isLoading}
        onRefresh={onRefresh}
      />

      <Box position={'relative'} borderRadius={'0.8rem 0.8rem 0 0'}>
        <NativeScroll
          style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
          mode={'visible'}
        >
          <DataGridLight columns={columns} rows={rows} rowProps={calcRowProps} />
        </NativeScroll>
        {rows.length === 0 && <DataGridEmpty />}
        {isLoading && <Loading />}
      </Box>
      <Stack alignItems={'flex-end'} mt={1} direction={'column'}>
        <Typography color={'textSecondary'}>{tp('total-items', { count: rows.length })}</Typography>
      </Stack>
    </Box>
  );
};
