import React, { useCallback } from 'react';
import { GridControlsDropDownTemplateProps } from 'models/grid';
import { useTranslate } from 'hooks/use-translate';
import { composeFunctions } from 'utils';
import { GridModel } from '../models';
import { useGridMedicalPrescriptions } from '../hooks';
import { useOpen } from 'AurionCR/components/hooks';
import { useAppSelector } from 'store';
import { selectLanguageID } from 'store/languages';
import { ServicePharmacyApiResponse } from 'services/pharmacy-api-responses';
import { apiUserPatientProfilePrescriptionActivities } from 'services/user-patient-profile-prescription-activities';
import { useEffectError, useMutationDynamic } from 'hooks';
import { apiServiceNeopharm } from 'services/service-neopharm';
import { DialogNeopharmValidity } from 'components/dialog-neopharm-validity';
import { apiUserPatientPrescriptions } from 'services/user-patient-profile-prescriptions';
import { BaseMenuItem } from '../base-menu-item';

const useCollectDataMutation = apiServiceNeopharm.useNeopharmGenerateDocumentsMutation;
const useCompleteMutation = apiUserPatientPrescriptions.useCompleteMedicalPrescriptionMutation;
const useLogMutation =
  apiUserPatientProfilePrescriptionActivities.useCreateUserPatientProfilePrescriptionActivityMutation;

interface Props extends GridControlsDropDownTemplateProps<GridModel> {}
const Control: React.FC<Props> = ({ data, handleClose }) => {
  const { prescriptionPrintFilePath, id, userPatientProfileID } = data;
  const { t, tp } = useTranslate();
  const disabled = !data.prescriptionPrintFilePath || !!data.pharmacyFollowupID;

  const { onTasks } = useGridMedicalPrescriptions();
  const stateValidity = useOpen();

  const languageID = useAppSelector(selectLanguageID);

  const [collectData] = useCollectDataMutation();

  const [complete, resultComplete] = useCompleteMutation();
  useEffectError(resultComplete.error);
  const [createLog, resultCreateLog] = useLogMutation();
  useEffectError(resultCreateLog.error);

  const prefrormSend = useCallback(async () => {
    if (!prescriptionPrintFilePath) return;

    const userPatientProfilePrescriptionID = id;

    const resCollect = await collectData({
      prescriptionPrintFilePath,
      userPatientProfilePrescriptionID,
      languageID,
    }).unwrap();

    await ServicePharmacyApiResponse.send({
      userPatientProfilePrescriptionID,
      prescriptionFilePath: resCollect.prescriptionFilePath,
      files: resCollect.files,
    });

    const resActivityLog = createLog({
      userPatientProfilePrescriptionID: id,
      remarks: tp('activity-patient-prescription-send-to-neopharm'),
    });

    const recComplete = complete({
      userPatientProfilePrescriptionID: id,
      remarks: tp('activity-patient-prescription-send-to-neopharm-complete'),
    });

    try {
      await Promise.all([resActivityLog.unwrap(), recComplete.unwrap()]);

      onTasks([['getData']]);

      handleClose();
    } catch {}
  }, [
    tp,
    id,
    prescriptionPrintFilePath,
    languageID,
    collectData,
    handleClose,
    onTasks,
    createLog,
    complete,
  ]);

  const [triggerSend, resultSend] = useMutationDynamic(prefrormSend);
  useEffectError(resultSend.error);

  return (
    <>
      <BaseMenuItem
        prescriptionID={String(data.id)}
        onClick={stateValidity.handleOpen}
        disabled={disabled}
      >
        {t('send-to-neopharm')}
      </BaseMenuItem>
      <div onKeyDown={(e) => e.stopPropagation()}>
        {stateValidity.isOpen && (
          <DialogNeopharmValidity
            userPatientProfileID={userPatientProfileID}
            prescriptionID={id}
            onClose={stateValidity.handleClose}
            onContinue={composeFunctions(stateValidity.handleClose, triggerSend)}
          />
        )}
      </div>
    </>
  );
};

export const ControlSendToNeopharm: React.FC<Props> = (props) => {
  if (props.data.isSample) return null;

  return <Control {...props} />;
};
