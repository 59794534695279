import { addMinutes, set } from 'date-fns';
import { iTimeLine } from './@type';
import { dateFormat, DateValue } from 'utils/dates';

export const createTimeLineID = (value: DateValue) => {
  return dateFormat(value, 'HH:mm');
};
export const createTimeline = (hoursFrom: number, hoursTo: number, minutes = 10) => {
  const result: iTimeLine[] = [];

  const minutesSlice = minutes / 60;

  let date = set(new Date(), {
    hours: hoursFrom,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  date = addMinutes(date, -minutes);

  for (let i = hoursFrom; i <= hoursTo; i += minutesSlice) {
    date = addMinutes(date, minutesSlice * 60);
    const id = createTimeLineID(date);

    result.push({ id, decimal: i });
  }

  return result;
};

export const convertTimeStringFromNumber = (value: number) => {
  return (value < 10 ? '0' + value : value) + ':00';
};
